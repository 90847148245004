import React from 'react'
import {
  Container,
  CommonBanner,
  Section,
  Seo,
} from '@components'
import { MDXRenderer } from "../../components/core/mdx";
import { PageLayout } from '../../components/site/layout/page-layout'

const LegalPageTemplate = ({ page, name, pathname }) => {
  return (
    <PageLayout pathname={pathname}>
      <CommonBanner title={page.title} />

      <Section className="py-2 md:py-4 lg:8">
        <Container>
          <MDXRenderer>{page.body}</MDXRenderer>
        </Container>
      </Section>
    </PageLayout>
  )
}

export { LegalPageTemplate }
