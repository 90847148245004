import * as React from 'react'
import { graphql } from 'gatsby'
import { LegalPageTemplate } from './legal-page-template'

const LegalPage = ({ data, pageContext }) => {
  const page = {
    pathname: pageContext.location.pathname,
    title: data.mdx.frontmatter.title,
    body: data.mdx.body,
  }

  return (
    <LegalPageTemplate
      page={page}
      name={pageContext.name}
      pathname={pageContext.location.pathname}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
      }
      body
    }
  }
`

export default LegalPage
